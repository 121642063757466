import React from "react"
import { Link } from "gatsby"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import CustomNavTitle from "../../components/CustomNavTitle"

import { theme } from "../../styles/theme"

const Administration = ({ location }) => {
  return (
    <Layout>
      <SEO title="Administration" />

      <CustomNavTitle
        title="Administration"
        destination="/"
        location={location}
      />

      <div className="mt-gap-2" css={theme.components.row}>
        <Link
          to="/administration/rate"
          className="mt-gap mb-gap"
          css={[
            theme.components.button,
            theme.components.buttonGrey,
            theme.components.buttonLink,
            {
              marginRight: theme.gapDouble,
            },
          ]}
        >
          Rate and giving sets
        </Link>

        <Link
          to="/administration/documentation"
          className="mt-gap mb-gap"
          css={[
            theme.components.button,
            theme.components.buttonGrey,
            theme.components.buttonLink,
          ]}
        >
          Documentation and monitoring
        </Link>
      </div>
    </Layout>
  )
}

export default Administration
